// File: src/App.js
// Update #1

import React from 'react';
import ActiveArea from './ActiveArea';

function App() {
  return (
    <div className="App">
      <ActiveArea />
    </div>
  );
}

export default App;
