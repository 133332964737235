// File: src/ActiveArea.jsx
// Update #8

import React, { useState, useEffect } from 'react';
// Remove this if not used in the component
import styles from './ActiveArea.module.css';
// eslint-disable-next-line no-unused-vars
import InputItem from './InputItem'; // Disabled warning since it's unused

const inputItems = [
  // Input items code
];

function ActiveArea() {
  const [activeInput, setActiveInput] = useState(null);
  const [linkId, setLinkId] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true); // Suppress the warning for 'loading' if unused
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null); 
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    if (!backendUrl) return;

    const fetchLinkId = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/getLinkId`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const result = await response.json();
          if (result.status === 'valid') {
            setLinkId(result.linkId);
          } else {
            setError('Link is invalid or already activated.');
          }
        } else {
          const errorMessage = await response.text();
          setError(`Failed to fetch link ID: ${errorMessage}`);
        }
      } catch (err) {
        setError(`Error during fetching link ID: ${err.message}`);
      }
    };

    fetchLinkId();
  }, [backendUrl]);

  // eslint-disable-next-line no-unused-vars
  const handleClick = (index) => {
    setActiveInput(index); // Suppress if unused
  };

  // eslint-disable-next-line no-unused-vars
  const handleActivate = async () => {
    if (activeInput === null) {
      alert("Please select an action first.");
      return;
    }

    const action = inputItems[activeInput].text;

    try {
      const response = await fetch(`${backendUrl}/api/activateLink`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          linkId,
          [action]: true,
        }),
      });

      if (response.ok) {
        const result = await response.json();
        setLinkId(result.linkId);
        alert('Link activated successfully!');
      } else {
        const errorMessage = await response.text();
        alert(`Failed to activate: ${errorMessage}`);
      }
    } catch (err) {
      alert(`Error during activation: ${err.message}`);
    }
  };

  return (
    <section className={styles.activeArea}>
      <div className={styles.content}>
        {/* JSX structure */}
      </div>
    </section>
  );
}

export default ActiveArea;
